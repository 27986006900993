/* eslint-disable import/no-extraneous-dependencies */
import firebase from 'firebase';
import * as React from 'react';
import {
  SaveButton,
  Toolbar,
  useRefresh,
  useRedirect,
  useNotify,
  useTranslate
} from 'react-admin';
import { useFormState } from 'react-final-form';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { isNil } from 'ramda';
import { API_URL } from '../../constants/constants';
import { graphqlServiceRequest } from '../../bitComponents/utils/requests';

const getVehicleAge = (minYearsAfterPresent, maxYearsAfterPresent) => {
  if (maxYearsAfterPresent === 0 && minYearsAfterPresent === 0) {
    return 'נוכחית';
  }

  if (maxYearsAfterPresent === minYearsAfterPresent) {
    return `נוכחית פחות ${maxYearsAfterPresent}`;
  }

  return `גיל רכב  ${minYearsAfterPresent} - ${maxYearsAfterPresent}`;
};

const isOverlapping = (newMin, newMax, existingMin, existingMax) => {
  return (
    (newMin <= existingMax && newMin >= existingMin) ||
    (newMax <= existingMax && newMax >= existingMin) ||
    (existingMin >= newMin && existingMax <= newMax)
  );
};

// Helper function to get the common funding rate data
const getFundingRateData = async (formVals) => {
  const {
    updatingUserUid,
    rate,
    numOfPayments,
    balloonPercent,
    minYearsAfterPresent,
    maxYearsAfterPresent
  } = formVals;

  const decimalRate = (rate / 100).toFixed(6);
  const decimalBallon = ((balloonPercent || 0) / 100).toFixed(6);
  const vehicleAge = getVehicleAge(minYearsAfterPresent, maxYearsAfterPresent);
  const sqlDate = new Date().toISOString().slice(0, 19).replace('T', ' ');
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();

  return {
    decimalRate,
    decimalBallon,
    vehicleAge,
    sqlDate,
    userUid: updatingUserUid || user.uid,
    token,
    minYearsAfterPresent,
    maxYearsAfterPresent,
    numOfPayments
  };
};

// Helper function to check for overlapping year ranges
const checkForOverlap = (
  minYearsAfterPresent,
  maxYearsAfterPresent,
  fundingRates,
  id
) => {
  return fundingRates.some(
    (fundingRate) =>
      fundingRate.nodeId !== id &&
      isOverlapping(
        minYearsAfterPresent,
        maxYearsAfterPresent,
        fundingRate.minYearsAfterPresent,
        fundingRate.maxYearsAfterPresent
      )
  );
};

const handleCreate = async (
  formVals,
  fundingRates,
  latestId,
  redirect,
  refresh,
  notify
) => {
  const { minYearsAfterPresent, maxYearsAfterPresent } = formVals;

  if (
    checkForOverlap(minYearsAfterPresent, maxYearsAfterPresent, fundingRates)
  ) {
    notify('טווח השנים שהוזן למסלול זה חופף למסלול אחר', 'warning');
    return;
  }

  const {
    decimalRate,
    decimalBallon,
    vehicleAge,
    sqlDate,
    userUid,
    token,
    numOfPayments
  } = await getFundingRateData(formVals);

  const id = isNil(latestId) || Number.isNaN(latestId) ? 1 : latestId;

  await graphqlServiceRequest(
    token,
    `mutation {
      createFundingRatesV2(input: {
        fundingRatesV2: {
          id: ${id},
          createdAt: "${sqlDate}", 
          updatedAt: "${sqlDate}", 
          creatingUserUid: "${userUid}", 
          updatingUserUid: "${userUid}", 
          numOfPayments: ${numOfPayments},
          balloonPercent: "${decimalBallon}",
          maxYearsAfterPresent: ${maxYearsAfterPresent},
          minYearsAfterPresent: ${minYearsAfterPresent},
          rate: "${decimalRate}", 
          previousRate: "${decimalRate}", 
          vehicleAge: "${vehicleAge}"
        }
      }) {
        fundingRatesV2 {
          createdAt
          creatingUserUid
          rate
        }
      }
    }`,
    API_URL
  )
    .then(() => {
      notify('מסלול חדש נוצר בהצלחה', 'info');
      redirect('/FundingRatesV2');
      refresh();
    })
    .catch(() => {
      notify('שגיאה בעת יצירת מסלול חדש', 'warning');
    });
};

const handleUpdate = async (
  formVals,
  id,
  fundingRates,
  redirect,
  refresh,
  notify
) => {
  const { minYearsAfterPresent, maxYearsAfterPresent } = formVals;

  if (
    checkForOverlap(
      minYearsAfterPresent,
      maxYearsAfterPresent,
      fundingRates,
      id
    )
  ) {
    notify('טווח השנים שהוזן למסלול זה חופף למסלול אחר', 'warning');
    return;
  }

  const {
    decimalRate,
    decimalBallon,
    vehicleAge,
    sqlDate,
    userUid,
    token,
    numOfPayments
  } = await getFundingRateData(formVals);

  await graphqlServiceRequest(
    token,
    `mutation {
      updateFundingRatesV2(input: {
        nodeId: "${id}",
        patch: {
          updatedAt: "${sqlDate}",
          updatingUserUid: "${userUid}",
          numOfPayments: ${numOfPayments},
          balloonPercent: "${decimalBallon}",
          maxYearsAfterPresent: ${maxYearsAfterPresent},
          minYearsAfterPresent: ${minYearsAfterPresent},
          rate: "${decimalRate}", 
          vehicleAge: "${vehicleAge}"
        }
      }) {
        fundingRatesV2 {
          ratesUpdatedAt
          updatingUserUid
          rate
        }
      }
    }`,
    API_URL
  )
    .then(() => {
      notify('מסלול עודכן בהצלחה', 'info');
      redirect('/FundingRatesV2');
      refresh();
    })
    .catch(() => {
      notify('שגיאה בעת עדכון המסלול', 'warning');
    });
};

const useStyles = makeStyles(() => ({
  deleteButton: {
    marginLeft: '1em',
    color: 'white',
    backgroundColor: 'red'
  },
  deleteIcon: {
    color: 'white'
  },
  deleteText: {
    marginLeft: '0.5em'
  }
}));

const handleDelete = async (id, redirect, refresh, notify) => {
  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();

  if (!id) return;

  await graphqlServiceRequest(
    token,
    `mutation {
       deleteAndUpdateFundingRates(input: { targetId: ${id} }) {
    clientMutationId
  }
}`,
    API_URL
  )
    .then(() => {
      notify('מסלול נמחק בהצלחה', 'info');
      redirect('/FundingRatesV2');
      refresh();
    })
    .catch(() => {
      notify('שגיאה בעת מחיקת מסלול', 'warning');
    });
};

// eslint-disable-next-line import/prefer-default-export
export const EditToolbar = ({ fundingRates, isNew, ...props }) => {
  const classes = useStyles();
  const formState = useFormState();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const notify = useNotify();
  const translate = useTranslate();
  const fundingRate = fundingRates.find((f) => f.nodeId === props.id);

  return (
    <Toolbar {...props}>
      <SaveButton
        disabled={props.invalid}
        handleSubmitWithRedirect={() =>
          isNew
            ? handleCreate(
                formState.values,
                fundingRates,
                props.latestId,
                redirect,
                refresh,
                notify
              )
            : handleUpdate(
                formState.values,
                props.id,
                fundingRates,
                redirect,
                refresh,
                notify
              )
        }
      />
      {!isNew && (
        <Button
          className={classes.deleteButton}
          variant="contained"
          onClick={() =>
            handleDelete(fundingRate.id, redirect, refresh, notify)
          }
        >
          <DeleteIcon className={classes.deleteIcon} />
          <Typography className={classes.deleteText} variant="button">
            {translate('ra.action.delete')}
          </Typography>
        </Button>
      )}
    </Toolbar>
  );
};
