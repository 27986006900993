import labels from '../../labels';
import FundingCalculatorCreate from './FundingCalculatorCreate';
import FundingCalculatorEdit from './FundingCalculatorEdit';
import FundingCalculatorList from './FundingCalculatorList';
// import FundingCalculatorUpdateList from './FundingCalculatorUpdateList';
// import { FundingCalcVariableUpdateEditList } from './FundingCalcVariableUpdateEditList';

export default {
  name: 'FundingRatesV2',
  list: FundingCalculatorList,
  edit: FundingCalculatorEdit,
  create: FundingCalculatorCreate,

  options: {
    label: labels.fundingCalculatorUpdatePage,
    group: 'parameters'
  }
};
