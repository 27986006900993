import React from 'react';
import {
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  TextField
} from 'react-admin';
import Box from '@material-ui/core/Box';
import FundingPercentageLimit from './FundingPercentageLimit';

const FundingCalculatorList = (props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '10px'
      }}
    >
      <List {...props} bulkActionButtons={false}>
        <Datagrid rowClick="edit">
          <FunctionField
            source="vehicleAge"
            label="מסלול"
            render={(record) => {
              return ` ${record.vehicleAge}`;
            }}
          />
          <FunctionField
            source="vehicleAge"
            label="שנתון הרכב"
            render={(record) => {
              const currentYear = new Date().getFullYear();
              const fromYear = currentYear - record.minYearsAfterPresent;
              const toYear = currentYear - record.maxYearsAfterPresent;

              return fromYear === toYear
                ? `${fromYear}`
                : `${fromYear} - ${toYear}`;
            }}
          />
          <FunctionField
            source="previousRate"
            label="ריבית נוכחית"
            render={(record) =>
              `${(parseFloat(record.previousRate) * 100).toFixed(2)} %`
            }
          />
          <FunctionField
            source="rate"
            label="ריבית חדשה"
            render={(record) =>
              `${(parseFloat(record.rate) * 100).toFixed(2)} %`
            }
          />
          <TextField source="user.name" label={'עודכן ע"י'} />
          <DateField
            showTime
            source="updatedAt"
            label="תאריך ושעת עדכון"
            locales="he-IL"
          />
          <DateField
            locales="he-IL"
            source="ratesUpdatedAt"
            label="תאריך ושעת עדכון מערכת"
            showTime
          />
          <EditButton />
        </Datagrid>
      </List>
      <FundingPercentageLimit />
    </Box>
  );
};

export default FundingCalculatorList;
