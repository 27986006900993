import { filter, map, pipe, prop } from 'ramda';
import { useEffect, useState } from 'react';
import { GET_LIST, useDataProvider, useVersion } from 'react-admin';

const useUsersForAutoComplete = (
  roles,
  uid = null,
  teamId = null,
  textOnly = false
) => {
  const [users, setUsers] = useState();
  const version = useVersion();
  const dataProvider = useDataProvider();

  const fetchUsers = async () => {
    const filters = {
      specificFields: [
        'nodeId',
        'uid',
        'name',
        'teamId',
        'role',
        'phone',
        'email',
        'secondaryRoleId'
      ]
    };

    if (roles && roles.length > 0) {
      filters.role = roles;
    }

    if (teamId) {
      filters.teamId = teamId;
    }

    if (uid) {
      filters.uid = uid;
    }

    const { data } = await dataProvider(GET_LIST, 'User', {
      filter: filters,
      sort: { field: 'name', order: 'ASC' },
      pagination: { page: 1, perPage: 2000 }
    });

    const mappedUsers = pipe(
      map((user) => ({
        id: prop(textOnly ? 'name' : 'uid', user),
        name: `${prop('name', user)}`,
        teamId: prop('teamId', user),
        role: prop('role', user),
        phone: prop('phone', user),
        email: prop('email', user),
        secondaryRoleId: prop('secondaryRoleId', user)
      })),
      filter(prop('id'))
    )(data);

    setUsers([...mappedUsers]);
  };

  useEffect(() => {
    fetchUsers();
  }, [version]);

  return {
    users
  };
};

export default useUsersForAutoComplete;
