import React from 'react';

import SpecificFieldsEdit from '../../components/SpecificFieldsEdit';
import FundingCalculatorMutation from './FundingCalculatorMutation';
import useFundingRates from '../../utils/hooks/useFundingRates';

const FundingCalculatorEdit = (props) => {
  const { fundingRates } = useFundingRates();

  if (!fundingRates) return null;

  return (
    <SpecificFieldsEdit
      {...props}
      specificFields={[
        'nodeId',
        'id',
        'rate',
        'vehicleAge',
        'numOfPayments',
        'minYearsAfterPresent',
        'maxYearsAfterPresent',
        'balloonPercent',
        'updatingUserUid',
        'updatedAt'
      ]}
    >
      <FundingCalculatorMutation
        {...props}
        isNew={false}
        fundingRates={fundingRates}
      />
    </SpecificFieldsEdit>
  );
};

export default FundingCalculatorEdit;
