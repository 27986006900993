import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import {
  maxValue,
  minValue,
  NumberInput,
  required,
  SimpleForm,
  TextInput,
  usePermissions
} from 'react-admin';

import useMaxYearsFromPresentLimit from './FundingPercentageLimit/hooks/useMaxYearsFromPresentLimit';
import useUsersForAutoComplete from '../../utils/hooks/useUsersForAutoComplete';
import { formatDate } from '../../utils/utils';
import { EditToolbar } from './EditToolbar';
import { MAX_BALLOON_PERCENTAGE } from './constants';

const useStyles = makeStyles((theme) => ({
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 0)
  },
  loader: {
    marginRight: theme.spacing(1)
  }
}));

const rateValidator = (value) => {
  if (!value) return undefined;

  if (value.toString().match('^([1-9]\\d{2,10})')) {
    return 'הערך צריך להיות פחות מ100%';
  }

  if (value.toString().match('^(0|[1-9]\\d{0,1})(\\.\\d{3,10})')) {
    return ' ריבית צריכה להיות עד 2 ספרות אחרי הנקודה';
  }

  return undefined;
};

const validateRate = [required(), rateValidator];

const maxYearsValidator = (maxYears) => (value, allValues) => {
  const minYears = allValues.minYearsAfterPresent;

  if (minYears > value) {
    return 'הערך של "עד כמות שנים לאחר שנה נוכחית" לא יכול להיות קטן מהערך של "מכמות שנים לאחר שנה נוכחית"';
  }

  if (value > maxYears) {
    return `הערך המקסימלי בשדה זה הינו ${maxYears}`;
  }

  return undefined;
};

const balloonPercentValidator = maxValue(
  MAX_BALLOON_PERCENTAGE,
  `אחוז בלון לא יכול להיות יותר מ-${MAX_BALLOON_PERCENTAGE}`
);

const FundingCalculatorMutation = (props) => {
  const classes = useStyles();
  const { permissions, loaded: permissionsLoaded } = usePermissions();
  const { users } = useUsersForAutoComplete(
    null,
    props.record.updatingUserUid || null
  );
  const [userName, setUserName] = useState('');
  const [updatingUserName, setUpdatingUserName] = useState(null);
  const [userUid, setUserUid] = useState('');
  const [record, setRecord] = useState(props.record || {});
  const { value: maxYears } = useMaxYearsFromPresentLimit();
  const isNew = props.initialMinYears !== undefined;

  useEffect(() => {
    if (users && users.length > 0) {
      setUpdatingUserName(users[0].name.trim() || null);
    }
  }, [users]);

  useEffect(() => {
    if (permissionsLoaded && permissions) {
      setUserUid(permissions.uid);
      setUserName(permissions.name);
      setRecord(props.record);
    }
  }, [permissionsLoaded, permissions, props.record]);

  return (
    <SimpleForm
      toolbar={
        <EditToolbar
          {...props}
          fundingRates={props.fundingRates || []}
          isNew={isNew}
        />
      }
    >
      <TextInput
        source="vehicleAge"
        label="מסלול"
        disabled
        defaultValue={record?.vehicleAge}
      />
      <NumberInput
        source="rate"
        label="ריבית"
        defaultValue={
          record && record.rate
            ? (parseFloat(record.rate) * 100).toFixed(2)
            : undefined
        }
        validate={validateRate}
        helperText="יש להזין אחוזים כמספרים שלמים, כלומר X.XX"
      />

      <NumberInput
        source="numOfPayments"
        label="מספר תשלומים"
        validate={[required(), minValue(1)]}
        defaultValue={record?.numOfPayments}
      />

      <NumberInput
        source="minYearsAfterPresent"
        label="מכמות שנים לאחר שנה נוכחית"
        defaultValue={
          isNew ? props.initialMinYears : record?.minYearsAfterPresent
        }
        disabled
        validate={isNew ? undefined : [required(), minValue(0)]}
      />

      <NumberInput
        source="maxYearsAfterPresent"
        label="עד כמות שנים לאחר שנה נוכחית"
        defaultValue={record?.maxYearsAfterPresent || 0}
        validate={[required(), minValue(0), maxYearsValidator(maxYears)]}
      />

      <NumberInput
        source="balloonPercent"
        label="אחוז בלון"
        defaultValue={record ? parseFloat(record.balloonPercent) * 100 : 0}
        validate={[rateValidator, balloonPercentValidator]}
        helperText="יש להזין אחוזים כמספרים שלמים, כלומר XX.XX"
      />

      <TextInput
        style={{ display: 'none' }}
        source="updatingUserUid"
        label="uid"
        defaultValue={record?.updatingUserUid || userUid}
        disabled
      />

      {!isNew &&
        props.record.updatingUserUid &&
        (updatingUserName ? (
          <TextInput
            source="updatingUserName"
            label="עודכן ע״י"
            defaultValue={updatingUserName}
            disabled
          />
        ) : (
          <div className={classes.loaderContainer}>
            <CircularProgress size={20} className={classes.loader} />
            <span>טוען...</span>
          </div>
        ))}

      {!isNew && (
        <TextInput
          source="updatedAt"
          label="תאריך ושעת עדכון"
          // defaultValue={isNew ? '' : `${formatDate(record?.updatedAt)}`}
          defaultValue={
            isNew
              ? ''
              : `${new Date(record?.updatedAt).toLocaleString('he-IL')}`
          }
          disabled
        />
      )}
    </SimpleForm>
  );
};

export default FundingCalculatorMutation;
