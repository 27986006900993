import { filter, map, pipe, prop } from 'ramda';
import { useEffect, useState } from 'react';
import { GET_LIST, useDataProvider, useVersion } from 'react-admin';

const useFundingRates = () => {
  const [fundingRates, setFundingRates] = useState();
  const version = useVersion();
  const dataProvider = useDataProvider();

  const fetchFundingRates = async () => {
    const { data } = await dataProvider(GET_LIST, 'FundingRatesV2', {
      filter: {
        specificFields: [
          'nodeId',
          'id',
          'rate',
          'vehicleAge',
          'numOfPayments',
          'minYearsAfterPresent',
          'maxYearsAfterPresent',
          'balloonPercent',
          'updatingUserUid',
          'creatingUserUid',
          'updatedAt',
          'createdAt',
          'originalId'
        ]
      },
      sort: { field: 'id', order: 'ASC' },
      pagination: { page: 1, perPage: 2000 }
    });

    const mappedFundingRates = pipe(
      map((fr) => ({
        id: prop('originalId', fr),
        nodeId: prop('nodeId', fr),
        name: `${prop('vehicleAge', fr)}`,
        numOfPayments: prop('numOfPayments', fr),
        minYearsAfterPresent: prop('minYearsAfterPresent', fr),
        maxYearsAfterPresent: prop('maxYearsAfterPresent', fr),
        balloonPercent: prop('balloonPercent', fr),
        updatingUserUid: prop('updatingUserUid', fr),
        updatedAt: prop('updatedAt', fr),
        creatingUserUid: prop('creatingUserUid', fr),
        createdAt: prop('createdAt', fr)
      })),
      filter(prop('id'))
    )(data);

    setFundingRates([...mappedFundingRates]);
  };

  useEffect(() => {
    fetchFundingRates();
  }, [version]);

  return {
    fundingRates
  };
};

export default useFundingRates;
